@import '../../main.scss';

.paginationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}

.paginationButtons {
  display: flex;
  flex-direction: row;
  align-items: center;

  * {
    margin: 0 0 0 8px;
  }
}

.paginationDots {
  font-size: 16px;
  font-family: var(--tia-font-family, 'Muli');
  color: #405e6c;
}

.paginationResult {
  font-weight: 700;
  font-family: var(--tia-font-family, 'Muli');
  font-size: 14px;
  color: #405e6c;
}
