html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: var(--tia-font-family), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}

a {
  text-decoration: none;
}
