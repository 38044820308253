@import '../../main.scss';

$barHeight: 70px;
$elementHight: $barHeight * 0.5;

@mixin breadcrumbTitle {
  font-weight: 300;
  font-family: var(--tia-font-family, 'Muli');
  font-size: 14px;
  color: #929aa8;
}

.nagivationBarContainer {
  height: $barHeight;
  border-bottom: 1px solid #e2e5ea;
  background-color: var(--color-navigationbar-background, #ffffff);
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.navigationBarWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logoContainer {
  min-width: 89px;
}

.logo {
  width: 100%;
  height: $elementHight;
  background: var(--tia-top-menu-logo-url, url(../../resources/media/tia-logo.svg));
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.title {
  @include breadcrumbTitle;
  margin-left: 25px;
}

.breadcrumbSeparator {
  @include breadcrumbTitle;
  margin: 0 8px;
}

.breadcrumbNavigation a {
  @include breadcrumbTitle;
}

.breadcrumbNavigation:last-child a {
  @include breadcrumbTitle;
  font-family: var(--tia-font-family, 'Muli');
  font-weight: 700;
  color: #405e6c;
}
