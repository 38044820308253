@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

$tablet-width: 768px;
$laptop-width: 992px;
$desktop-width: 1200px;

$mob-button-height: 65px;
$mob-botton-margin: 15px;

$mob-menu-item-height: 50px;
$desk-menu-item-height: 65px;

$mob-menu-width: 275px;
$desk-menu-width: 100px;

$footer-height: 40px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}
@mixin laptop {
  @media (min-width: #{$laptop-width}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin clean-button {
  outline: 0;
  border: none;
}

@mixin main-font {
  font-family: 'Muli', sans-serif;
}
