@import '../../main.scss';

.menuItemContainer {
  width: calc(100% - 32px);
  height: 48px;
  font-weight: bold;
  background-color: var(--tia-menu-item-bg, transparent);
  color: var(--tia-menu-item-color, #8db2bf);
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 20px;
  overflow: hidden;

  &:hover:not(.isActive) {
    background: var(--tia-menu-item-hover, #f3f4f7);
    border-radius: 24px;
  }
}

.collapsedItemContainer {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.isActive {
  color: var(--tia-menu-item-active-color, #ff9181);
  background: var(--tia-menu-item-active-bg-transparent, rgba(255, 145, 129, 0.1));
  border-radius: 24px;
}

.menuIcon {
  padding-top: 5px;
}

.menuTitle {
  font-family: var(--tia-font-family, 'Muli');
  font-size: 14px;
  padding-left: 15px;
}
