@import '../../main.scss';

.appContainer {
  height: 100%;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: -($footer-height + 1);
  position: relative;
  max-height: 100%;
}

.content {
  width: 100%;
  min-height: calc(100vh - 112px);
  transition: 0.3s;
  display: grid;
}

.appContent {
  width: 100%;
}

.push {
  height: ($footer-height + 1);
}
