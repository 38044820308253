.emptyWrapper {
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentWrapper {
  position: relative;
}

.tableHeader {
  display: flex;
  align-items: center;
  & > * {
    margin-left: 5px;
  }
  z-index: 5;
}

.tableInfo {
  font-family: var(--tia-font-family, 'Muli');
  font-weight: bold;
  color: #ff9282;
}

.editButton {
  position: absolute;
  z-index: 2;
  margin-top: 2px;
  left: 170px;
  top: 1px;
}
