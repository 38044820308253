@import '../../main.scss';

.sectionContainer {
  width: 100%;
  height: 100%;
}

.employeesHeader {
  display: flex;
  flex-direction: row;
  padding-bottom: 11px;
}

.employeeTableCell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
}

.employeesHeaderWrapperSelect {
  margin: 2px 0 0 8px;
  width: 380px;
}

.employeesHeaderButtonWrapper {
  margin: 0 0 0 16px;
  display: flex;
  align-items: center;
}

.nameLink {
  cursor: pointer;
  text-decoration: underline;
}

.rowLoading {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
}

.selectButton {
  all: unset;
  width: 100%;
}

.searchCell {
  display: flex;
  min-width: inherit;
  padding: 8px 8px 8px 4px;
  margin-right: 4px;
  border-radius: 8px;
  border: 1px solid transparent;
  position: relative;
  max-width: 100%;
  align-items: center;

  .dropdownValue {
    margin-left: 4px;
    position: relative;
    min-width: 0;
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .dropdownClear {
    cursor: pointer;
  }

  .dropdownContainer {
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: normal;
  }

  .employeeDropdown {
    background-color: white;
    position: absolute;
    top: 0;
    padding: 7px 0;
    min-width: 230px;
    box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.07);
    border-radius: var(--tia-dropdown-border-radius, 0.5rem);
  }

  &.searchButtonActive,
  &:hover {
    border-color: #dde0e7;
  }

  label {
    min-height: 24px;
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: hidden;

    select {
      all: unset;
      margin-left: 4px;
      min-width: 0;
      text-transform: uppercase;
    }
  }

  input {
    all: unset;
    margin-left: 4px;
    min-width: 0;
    text-transform: uppercase;
    min-height: 24px;

    &:before {
      content: '';
      width: 4px;
      height: 1px;
    }
  }

  input::placeholder {
    color: #405e6c;
  }
  input:placeholder-shown {
    text-overflow: ellipsis;
  }
}

.option {
  cursor: pointer;
  font-size: 14px;
  padding: 0 12px !important;
  min-height: 40px;
  color: #4f5c73;
  border-bottom: 1px solid #f3f4f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.option--selected {
  @extend .option;
  background-color: #fafbfe;
  font-weight: 800;
}
