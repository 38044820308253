@import '../../main.scss';

.footerWrapper {
  width: 100%;
  height: $footer-height;
  line-height: $footer-height;
  border-top: 1px solid var(--tia-divider, #edeef3);
  text-align: center;
  font-family: var(--tia-font-family, 'Muli');
  font-size: 12px;
  color: #ccc;
  background-color: var(--tia-panel-bg, #ffffff);
}
