.ant-modal .ant-modal-header {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  padding: 10px 24px;
}

.ant-modal .ant-modal-footer {
  background: transparent;
  padding: 10px 16px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  margin-left: -3px;
  margin-right: -3px;

  * {
    margin-left: 3px;
    margin-right: 3px;
  }
}
.ant-modal .ant-modal-body {
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.5715;
  padding: 12px;
}
