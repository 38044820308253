@import '../../main.scss';

.sectionContainer {
  width: 100%;
  height: 100%;
  min-width: 800px;
}

.employeesHeader {
  display: flex;
  flex-direction: row;
}

.employeesHeaderButtonWrapper {
  margin: 0 0 0 16px;
}
