@import '../../main.scss';

.menuContainer {
  background-color: white;
  border-right: 1px solid #e2e5ea;
  background-color: var(--tia-left-menu-bg, #ffffff);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.expandButton {
  bottom: 50px;
  position: absolute;
  cursor: pointer;
}
